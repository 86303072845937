import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faInstagram,
  faFacebook,
  faTwitter,
  faYoutube,
  faSpotify,
  faSoundcloud,
} from '@fortawesome/free-brands-svg-icons';
import Iframe from 'react-iframe';
import Lottie from './components/Logo';
// import logomark from './assets/bbyvlntn_logomark_pink.svg';
import logotype from './assets/bbyvlntn_logotype_black.svg';
import './App.css';

library.add(
  faInstagram,
  faFacebook,
  faTwitter,
  faYoutube,
  faSpotify,
  faSoundcloud
);

const App: React.FC = () => {
  const artistName = 'Baby Valentine';
  const artstEmail = 'info@bbyvlntn.com';
  const artistLinks = [
    {
      _id: '8c421b8b13014668b0f5d7de30e55a6c',
      type: 'facebook',
      url: 'https://facebook.com/bbyvlntn',
    },
    {
      _id: '24982a0c84104083b05d77676914230a',
      type: 'twitter',
      url: 'https://twitter.com/bbyvlntn',
    },
    {
      _id: '6d1812a003df47978b6ef34aaafd1244',
      type: 'instagram',
      url: 'https://instagram.com/bbyvlntn',
    },
    {
      _id: '5df9857759e7fc97af3fd3ef',
      type: 'spotify',
      url: 'https://open.spotify.com/artist/5RZPwOUVqhlKFVXG03fCgW',
    },
    {
      _id: '5df9857e01ea47b34fd1feaa',
      type: 'youtube',
      url: 'https://www.youtube.com/channel/UC6OsY39gGIvDrrAKxQ-e_Bw',
    },
    {
      _id: '5df98585518ae35d8822235e',
      type: 'soundcloud',
      url: 'https://soundcloud.com/bbyvlntn',
    },
  ];

  const currentYear = new Date().getFullYear();

  return (
    <div className="App">
      <header className="App-header">
        <Lottie />
        <img
          src={logotype}
          className="App-logotype"
          alt="Baby Valentine Logotype"
        />
        <Iframe
          url="https://open.spotify.com/embed/track/1FHRrxIl60mo1Mx2RVWBbF"
          width="300"
          height="80"
          frameBorder={0}
          allow="encrypted-media"
          className="spotify-player"
          styles={{ backgroundColor: 'transparent' }}
        />
      </header>
      <footer>
        <p>
          {artistLinks.map(item => {
            const { type, _id: id, url } = item;
            let icon: IconDefinition;
            switch (type) {
              case 'facebook':
                icon = faFacebook;
                break;
              case 'instagram':
                icon = faInstagram;
                break;
              case 'twitter':
                icon = faTwitter;
                break;
              case 'youtube':
                icon = faYoutube;
                break;
              case 'spotify':
                icon = faSpotify;
                break;
              case 'soundcloud':
                icon = faSoundcloud;
                break;
              default:
                return false;
            }
            return (
              <a
                key={id}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className={`${type} social light`}
              >
                <FontAwesomeIcon icon={icon} size="1x" />
              </a>
            );
          })}
        </p>
        <p>
          <a className="light" href={`mailTo:${artstEmail}`}>
            {artstEmail}
          </a>
        </p>
        <p>
          &copy;
          {` ${currentYear} ${artistName}`}
        </p>
      </footer>
    </div>
  );
};

export default App;
